<template>
        <el-dialog title="Right Ad Items" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
            <el-col :md='24'>
                <el-form-item prop="uploadPreview_Image" label='Image' >
                <el-upload
                        class='my-uploader'
                        :action="baseUrl + 'Right_ad_items/upload_Image'"
                        :show-file-list='false'
                        :on-success='handleUploadSuccess_Image'>
                            <i class='el-icon-plus my-uploader-icon'></i>
                        </el-upload>       
                        <img class="imagePreview" v-if='selectedObject.uploadPreview_Image' :src='selectedObject.uploadPreview_Image' > 
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="IdRightAd" label='Right Ad' >
                    <el-select class='full-width' v-model='selectedObject.IdRightAd' >
                        <el-option v-for="item in RightAds" :key="item.Id" :label='item.Position' :value='item.Id'></el-option>
                     </el-select>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="DataAscundereAfisare" label='Data Ascundere Afisare' >
                    <el-date-picker  class='full-width' v-model='selectedObject.DataAscundereAfisare' type='date' value-format='yyyy-MM-dd' format='yyyy-MM-dd' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="DataPublicare" label='Data Publicare' >
                    <el-date-picker  class='full-width' v-model='selectedObject.DataPublicare' type='date' value-format='yyyy-MM-dd' format='yyyy-MM-dd' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="Link" label='Link' >
                    <el-input  class='full-width' v-model='selectedObject.Link' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item prop="Position" label='Position' >
                    <el-input-number class='full-width' v-model='selectedObject.Position' :precision='0' :max='999999'></el-input-number>
                </el-form-item>
            </el-col>            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Right_ad_items_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                RightAds: [],
                selectedObject: {
                    Image: '' , uploadPreview_Image : '' , DataAscundereAfisare: '' , DataPublicare: '' , Link: '' , Position: '' ,                 },
                Info:{
                                    },
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    Image: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DataAscundereAfisare: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DataPublicare: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Link: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Position: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                    this.selectedObject = {};
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("right_ad_items/get_info_item_dialog", { id: id } );
                    console.log(result);
                    this.RightAds         = result.RightAds;
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("right_ad_items/get_info_for_dialog" );
                this.RightAds = response.RightAds;
            },
             handleUploadSuccess_Image(response){
                    this.selectedObject.Image = response.FileName;
                    this.selectedObject.uploadPreview_Image  = response.Url;
                },
                    save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("right_ad_items/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    
    .imagePreview {
        width: 150px;
        height: 150px;
        object-fit: contain;
    }

</style>